import '/@front:css/hbm.css'

import { createApp } from 'vue'

import { registerComponents } from '/@front:shared/components'
import { registerPlugins } from '/@front:shared/plugins'
import { registerTelInput } from '/@front:shared/plugins/tel-input'

const app = createApp({
  name: 'HbmForm',
  data: () => {
    return {}
  },
  methods: {},
})

registerComponents(app)
registerPlugins(app)
registerTelInput(app)

app.mount('#hbm-app')
